import { wait } from "library/utils";
import { useEffect } from "react";
import { contact } from "../utils/postmark";

function reload(maxAttempts = 5) {
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  let attempts = parseInt(urlParams.get("attempts") || "0");

  if (attempts >= maxAttempts) {
    if (window.location.pathname != "/") {
      window.location.replace(window.location.origin);
      return true;
    }
  } else {
    attempts += 1;
    urlParams.set("attempts", `${attempts}`);
    window.location.replace(`${currentUrl.split("?")[0]}?${urlParams}`);
    return true;
  }

  return false;
}

const sendErrorEmail = async (error: Error) => {
  // Assume failed to load errors are not site errors
  if (error?.message?.indexOf("Load failed") > -1) return;

  try {
    const emailResponse = await contact({
      from: "joe@1of1studio.com",
      to: "joe@1of1studio.com",
      subject: "YM: Report from Window Error",
      body: `
        <p>An error occurred in the application:</p>
        <p><strong>Location:</strong> ${window?.location?.href}</p>
        <p><strong>Error:</strong> ${error?.message || error}</p>
        <p><strong>Trace:</strong> ${JSON.stringify(error, null, 2)}</p>
      `,
    });
    console.log("Error email sent successfully", emailResponse);
    if (
      ["Failed to load static props", "Failed to fetch"].includes(
        error?.message,
      )
    ) {
      await wait();
      reload();
    }
  } catch (err) {
    console.error("Failed to send error email", err);
  }
};

function useWindowErrorEvents() {
  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      console.error("Unhandled rejection:", event.reason);
      // if (!isDevelopment) sendErrorEmail(event.reason);
    };

    const handleError = (event: ErrorEvent) => {
      console.error("Handled Error:", event.error);
      // if (!isDevelopment) sendErrorEmail(event.error);
    };

    window.addEventListener("unhandledrejection", handleUnhandledRejection);
    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection,
      );
      window.removeEventListener("error", handleError);
    };
  }, []);
}

export default useWindowErrorEvents;
