const defaultDescription = "Yu Mei is a leather goods label devoted to the creation of understated luxury. Each bag design is born from the ethos that simplicity is complexity resolved, built for purpose to carry with ease."

const NextSeoConfig = {
  title:"Yu Mei",
  description: defaultDescription,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://www.yumeibrand.com/',
    site_name: 'Yu Mei',
    description: defaultDescription,
  },
  twitter: {
    handle: '@yumeibrand',
    site: '@yumeibrand',
    cardType: 'summary_large_image',
  },
}

export default NextSeoConfig;