import Axios from 'axios';

interface ContactFields {
  from: string
  to: string
  subject: string 
  body: string 
}


export const contact = async(input: ContactFields) => {
  return Axios.post('/api/contact', input);
};