import React from 'react'
import {contact} from '../utils/postmark';

type ErrorBoundaryProps = {
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: any): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo)
    this.sendErrorEmail(error, errorInfo)
  }

  async sendErrorEmail(error: Error, errorInfo: React.ErrorInfo) {
    try {
      const emailResponse = await contact({
        from: "joe@1of1studio.com",
        to: "joe@1of1studio.com",
        subject: "YM: Report from ErrorBoundary",
        body: `
          <p>An error occurred in the application:</p>
          <p><strong>Error:</strong> ${error.message}</p>
          <p><strong>Stack:</strong> ${error.stack}</p>
          <p><strong>Component Stack:</strong> ${errorInfo.componentStack}</p>
        `
      })
      console.log('Error email sent successfully', emailResponse)
    } catch (err) {
      console.error('Failed to send error email', err)
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
