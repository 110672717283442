import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import NextSeoConfig from "../../next-seo.config";
import { useGtag } from "../hooks/useGtag";
import Script from "next/script";
import ErrorBoundary from "../components/ErrorBoundary";
import useWindowErrorEvents from "../hooks/useWindowErrorEvents";
import { GOOGLE_TAG_MANAGER_ID } from "../utils/config";
import { isProd } from "../utils/env";

type NoopProps = {
  children: React.ReactNode;
};

const PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

const Noop: React.FC<NoopProps> = ({ children }: NoopProps) => <>{children}</>;

function Application({ Component, pageProps, router }: AppProps) {
  const Layout = (Component as any).Layout || Noop;
  useWindowErrorEvents();
  useGtag();

  return (
    <>
      <ErrorBoundary>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`}
        />

        <Script
          id="GoogleAnalytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: /* js */ `
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('config', 'G-311659416');
              gtag('config', 'UA-102652532-1');
            `,
          }}
          crossOrigin="anonymous"
        />

        <Script
          id="FacebookPixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${PIXEL_ID}');
            `,
          }}
        />

        {isProd() && (
          <Script
            id="HotJar"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3069954,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
            }}
          />
        )}

        <Script
          id="gorgias-chat-widget-install-v3"
          strategy="lazyOnload"
          src="https://config.gorgias.chat/bundle-loader/01GYCBTV295ANPHCKW8HH4D2QA"
        />

        <Script
          id="klaviyoScript"
          strategy="afterInteractive"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=W6PbxD"
        />

        <DefaultSeo {...NextSeoConfig} />
      </ErrorBoundary>

      <ErrorBoundary>
        <Layout pageProps={pageProps} withLayout={true}>
          <ErrorBoundary>
            <Component {...pageProps} router={router} key={router.asPath} />
          </ErrorBoundary>
        </Layout>
      </ErrorBoundary>
    </>
  );
}

export default Application;
