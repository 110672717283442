import { useCustomer } from "frontend/src/contexts/CustomerProvider";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useEffectOnce } from "react-use";
import { GOOGLE_TAG_MANAGER_ID } from "../utils/config";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!window.gtag) return;
  window?.gtag("config", GOOGLE_TAG_MANAGER_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value,
}: {
  [key: string]: string;
}) => {
  if (!window.gtag) return;
  window?.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export function useGtag() {
  const router = useRouter();

  const handleRouteChange = (url: string) => {
    pageview(url);
  };

  useEffectOnce(() => {
    handleRouteChange(router.asPath);
  });

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
}
